// @ts-ignore
export const dataCurrency = window.currency.value;

export const dataMenuItem = [
    { price: 0, title: 'Выберите тип ленты'},
    { price: '2.54,', title: '200 х 2 мм (стандарт)' },
    { price: '2.57,', title: '200 х 2 мм (стандарт ребро)' },
    { price: '3.66,1', title: '300 х 2 мм (стандарт)' },
    { price: '3.69,1', title: '300 х 2 мм (стандарт ребро)' },
    { price: '4.93,1', title: '300 х 3 мм (стандарт)' },
    { price: '4.96,1', title: '300 х 3 мм (стандарт ребро)' },
    { price: '2.84,', title: '200 х 2 мм (хладостой)' },
    { price: '2.84,', title: '200 х 2 мм (хладостой ребро)' },
    { price: '3.96,1', title: '300 х 2 мм (хладостой)' },
    { price: '3.96,1', title: '300 х 2 мм (хладостой ребро)' },
    { price: '5,1', title: '300 х 3 мм (хладостой)' },
    { price: '5,1', title: '300 х 3 мм (хладостой ребро)' },
];

export const dataMount = [
    { price: 0, title: 'Выберите тип крепления' },
    { price: 280, title: 'Оцинковка' },
    { price: 360, title: 'Нержавейка' },
]


export const dataMatchTapes = [
    {widthTape: 0.6, twenty: 4, thirty: 3},
    {widthTape: 0.7, twenty: 5, thirty: 4},
    {widthTape: 0.8, twenty: 5, thirty: 4},
    {widthTape: 0.9, twenty: 6, thirty: 4},
    {widthTape: 1.0, twenty: 6, thirty: 4},
    {widthTape: 1.1, twenty: 7, thirty: 5},
    {widthTape: 1.2, twenty: 7, thirty: 5},
    {widthTape: 1.3, twenty: 8, thirty: 6},
    {widthTape: 1.4, twenty: 9, thirty: 6},
    {widthTape: 1.5, twenty: 10, thirty: 7},
    {widthTape: 1.6, twenty: 10, thirty: 7},
    {widthTape: 1.7, twenty: 11, thirty: 8},
    {widthTape: 1.8, twenty: 12, thirty: 8},
    {widthTape: 1.9, twenty: 12, thirty: 8},
    {widthTape: 2.0, twenty: 13, thirty: 9},
    {widthTape: 2.1, twenty: 13, thirty: 9},
    {widthTape: 2.2, twenty: 14, thirty: 10},
    {widthTape: 2.3, twenty: 15, thirty: 10},
    {widthTape: 2.4, twenty: 16, thirty: 11},
    {widthTape: 2.5, twenty: 16, thirty: 11},
    {widthTape: 2.6, twenty: 17, thirty: 12},
    {widthTape: 2.7, twenty: 17, thirty: 12},
    {widthTape: 2.8, twenty: 18, thirty: 13},
    {widthTape: 2.9, twenty: 19, thirty: 13},
    {widthTape: 3.0, twenty: 20, thirty: 14},
    {widthTape: 3.1, twenty: 20, thirty: 14},
    {widthTape: 3.2, twenty: 21, thirty: 15},
    {widthTape: 3.3, twenty: 21, thirty: 15},
    {widthTape: 3.4, twenty: 22, thirty: 15},
    {widthTape: 3.5, twenty: 23, thirty: 16},
    {widthTape: 3.6, twenty: 23, thirty: 16},
    {widthTape: 3.7, twenty: 24, thirty: 17},
    {widthTape: 3.8, twenty: 25, thirty: 17},
    {widthTape: 3.9, twenty: 25, thirty: 18},
    {widthTape: 4.0, twenty: 26, thirty: 18},
    {widthTape: 4.1, twenty: 0, thirty: 19},
    {widthTape: 4.2, twenty: 0, thirty: 19},
    {widthTape: 4.3, twenty: 0, thirty: 20},
    {widthTape: 4.4, twenty: 0, thirty: 20},
    {widthTape: 4.5, twenty: 0, thirty: 21},
    {widthTape: 4.6, twenty: 0, thirty: 21},
    {widthTape: 4.7, twenty: 0, thirty: 22},
    {widthTape: 4.8, twenty: 0, thirty: 22},
    {widthTape: 4.9, twenty: 0, thirty: 23},
    {widthTape: 5.0, twenty: 0, thirty: 23},
]
